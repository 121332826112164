import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import * as Layout from "@42maru/web-starter";
import AgentSelector from "../AgentSelector";
import { AppSwitcher, ClientEnvironment } from "@42maru/keeper-web-tool";
import { useIntl } from "react-intl";
import { useStores } from "../../hooks/useStores";
import { observer } from "mobx-react-lite";
import * as authenticationApis from "../../apis/authenticationApis";
import { useErrorBoundary } from "../../hooks/useErrorBoundary";
import { KEEPER_API_HOST, KEEPER_CLIENT_ID, KEEPER_HOST, KEEPER_ENVIRONMENT } from "../../variables";

const Menu: React.FC = observer(() => {
    const { accountStore, recentAgentsStore } = useStores();

    const { agentId } = useParams<{ agentId: string }>();
    const intl = useIntl();
    const location = useLocation();
    const errorBoundary = useErrorBoundary();

    useEffect(() => {
        accountStore.getProfile().then(() => {});

        return () => {
            accountStore.clear();
        };
    }, [accountStore]);

    const menuContent = [
        {
            icon: "uil-home-alt",
            label: intl.formatMessage({ id: "m01" }),
            to: `/${agentId}/dashboard`
        },
        // {
        //     icon: "uil-chat",
        //     label: intl.formatMessage({id: 'm02'}),
        //     to: `/${agentId}/conversation`
        // },
        {
            icon: "uil-layer-group",
            label: intl.formatMessage({ id: "m03" }),
            content: [
                {
                    label: intl.formatMessage({ id: "m03" }),
                    to: `/${agentId}/domain`
                },
                {
                    label: intl.formatMessage({ id: "m04" }),
                    to: `/${agentId}/typos`
                },
                {
                    label: intl.formatMessage({ id: "m05" }),
                    to: `/${agentId}/entities`
                },
                {
                    label: intl.formatMessage({ id: "m06" }),
                    to: `/${agentId}/synonyms`
                },
                {
                    label: intl.formatMessage({ id: "m07" }),
                    to: `/${agentId}/selector`
                },
                {
                    label: intl.formatMessage({ id: "m08" }),
                    to: `/${agentId}/autocompletes`
                }

                // {
                //     label: intl.formatMessage({ id: "m14" }),
                //     to: `/${agentId}/sentence-cluster`
                // }
            ]
        },
        {
            icon: "uil-file-check-alt",
            label: intl.formatMessage({ id: "m09" }),
            to: `/${agentId}/simulation/test`
        },
        {
            icon: "uil-cog",
            label: intl.formatMessage({ id: "m13" }),
            to: `/${agentId}/settings`
        },
        {
            icon: "uil-users-alt",
            label: intl.formatMessage({ id: "m16" }),
            to: `/${agentId}/members`
        }
    ];

    const onClickLogout = () => {
        authenticationApis.logout().then(() => {
            window.location.reload();
        });
    };

    useEffect(() => {
        recentAgentsStore.loadPermissions(Number(agentId));
    }, [agentId, recentAgentsStore]);

    const permissions = recentAgentsStore.permissions;

    const permissionNames = (permissions || []).map((value) => {
        return value.replace("view:", "");
    });
    const hassAllPermission = permissionNames.includes("*");
    let filteredMenuContent: any[] = [];

    if (hassAllPermission) {
        filteredMenuContent = menuContent;
    } else {
        filteredMenuContent = menuContent
            .map((value) => {
                if (value.to) {
                    const lastIndex = value.to.lastIndexOf("/");
                    const pathString = value.to.substring(lastIndex + 1, value.to.length);
                    if (permissionNames.includes(pathString)) {
                        return value;
                    } else {
                        return undefined;
                    }
                } else if (value.content) {
                    const newValue = value;
                    newValue.content = value.content
                        .map((innerValue) => {
                            const lastIndex = innerValue.to.lastIndexOf("/");
                            const pathString = innerValue.to.substring(lastIndex + 1, innerValue.to.length);
                            if (permissionNames.includes(pathString)) {
                                return innerValue;
                            } else {
                                return undefined;
                            }
                        })
                        .filter((item) => item) as any;
                    if (newValue.content.length > 0) {
                        return newValue;
                    } else {
                        return undefined;
                    }
                }
                return value;
            })
            .filter((item) => item);
    }

    const lastIndex = location.pathname.lastIndexOf("/");
    const pathString = location.pathname.substring(lastIndex + 1, location.pathname.length);

    if (
        !hassAllPermission &&
        !["/agents", "/agents/registration"].includes(location.pathname) &&
        recentAgentsStore.isPermissionLoaded &&
        (permissionNames.length === 0 || (permissionNames.length > 0 && !permissionNames.includes(pathString)))
    ) {
        errorBoundary.setStatus(403);
    }

    useEffect(() => {
        return () => {
            recentAgentsStore.clearPermissions();
        }
    }, [recentAgentsStore]);

    if (agentId) {
        return (
            <Layout.SideMenu>
                <Layout.Brand homeUrl={"/agents"} />
                <AgentSelector />
                {filteredMenuContent.length > 0 && <Layout.Menu content={filteredMenuContent} path={location.pathname} />}
                {accountStore.account && (
                    <AppSwitcher
                        keeperHost={KEEPER_HOST as string}
                        keeperAPIHost={KEEPER_API_HOST as string}
                        clientId={`${KEEPER_CLIENT_ID}`}
                        user={accountStore.account}
                        environment={KEEPER_ENVIRONMENT as ClientEnvironment}
                        onClickLogout={onClickLogout}
                    />
                )}
            </Layout.SideMenu>
        );
    } else {
        return null;
    }
});

export default Menu;
