import { action, observable } from "mobx";
import { EntityName } from "../model/Entity";
import { StoreState } from "../model/StoreState";
import * as entityApis from "../apis/entityApis";
import {EntityTypes} from "./EntityNameListStore";

class EntityNameALLStore {
    @observable
    data: EntityName[] = [];

    @observable
    state: StoreState = "none";

    @observable
    showingEntityTypes: EntityTypes[] = ["normal-entity", "post-entity", "ignore-entity"];

    @action
    async load(agentId: number,
               domainId: number
    ) {
        this.state = "pending";
        try {
            const { data } = await entityApis.findEntityNames(
                agentId,
                domainId,
                undefined,
                undefined,
                undefined,
                true,
                this.showingEntityTypes.join(",")
            )
            this.data = data.data;
            this.state = "done";
        } catch (e) {
            this.state = "error";
            throw e;
        }
    }

    @action
    clear() {
        this.data = [];
        this.state = "none";
    }
}

export default EntityNameALLStore;
