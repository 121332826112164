const translation: Record<string, string> = {
    m01: "대시보드",
    m02: "대화",
    m03: "도메인",
    m04: "오타교정",
    m05: "엔티티",
    m06: "유의어",
    m07: "셀렉터",
    m08: "자동완성",
    m09: "시뮬레이션",
    m10: "대량 테스트",
    m11: "테스트 결과",
    m12: "통계",
    m13: "설정",
    m14: "클러스터링",
    m15: "배포 버전",
    m16: "멤버 관리",
    i000001: "성공",
    i000002: "실패",
    i000003: "변동",
    i000004: "인텐트",
    i000005: "도메인에 개체명, 스킬-인텐트, 슬롯 등을 정의합니다.",
    i000006: "자세히 보기",
    i000007: "인텐트를 활성화 하시겠나요?",
    i000008: "인텐트를 비활성화 하시겠나요?",
    i000009: "활성화",
    i000010: "비활성화",
    i000011: "인텐트 활성화",
    i000012: "인텐트 비활성화",
    i000013: "삭제를 완료 했습니다.",
    i000014: "등록을 완료 했습니다.",
    i000015: "수정을 완료 했습니다.",
    i000016: "인텐트에 새로운 학습문장을 입력하세요.",
    i000017: "유의어",
    i000018: "유의어를 관리합니다.",
    i000019: "엑셀 다운로드",
    i000020: "등록된 대표 키워드, 유의어를 엑셀파일로 다운로드 합니다.",
    i000021: "대표 키워드는 필수 입력란 입니다.",
    i000022: "대표 키워드를 입력하세요.",
    i000023: "유의어를 입력하세요.",
    i000024: "저장",
    i000025: "취소",
    i000026: "업로드를 완료 했습니다.",
    i000027: "대량 업로드",
    i000028: "대량의 대표키워드, 유의어 사전 데이터를 엑셀 파일을 통해 진행하세요.",
    i000029: "클릭하거나 파일을 끌어와서 업로드 합니다.",
    i000030: "업로드",
    i000031: "필수 입력란 입니다.",
    i000032: "엔티티 이름을 입력하세요.",
    i000033: "Normal ENTITY",
    i000034: "POST ENTITY",
    i000035: "Ignore ENTITY",
    i000036: "엔티티 이름을 수정해 보세요.",
    i000037: "NER 사전에 새로운 엔티티 이름을 등록해 주세요.",
    i000038: "엔티티 이름은 엔티티 구분을 위한 단위입니다. 예) 'MOBILE_DEVICE'",
    i000039: "MOBILE_DEVICE 의 경우 v40, 갤럭시, 아이폰 등의 엔티티를 추가해주세요.",
    i000040: "POST ENTITY 는 인텐트 분류 결과에는 영향을 주지 않습니다.",
    i000041: "IGNORE ENTITY 가 태깅되면 사용자 질의중 해당 엔티티의 값을 공백으로 치환합니다.",
    i000042: "닫기",
    i000043: "대량 테스트",
    i000044: "테스트 케이스 정확도를 시뮬레이션 해보세요.",
    i000045: "테스트 등록",
    i000046: "예시) 엑셀 파일 형식",
    i000047: "엑셀 파일 형식",
    i000048: "테스트 제목을 입력하세요.",
    i000049: "테스트 목적을 입력하세요.",
    i000050: "제목",
    i000051: "목적",
    i000052: "엔티티 이름, 엔티티 값, 유의어를 엑셀을 통해 대량으로 등록합니다.",
    i000053: "체크아웃을 완료 했습니다.",
    i000054: "새로운 버젼에 도메인, 스킬, 인텐트 학습 데이터를 입력하세요.",
    i000055: "서비스에는 전혀 영향을 미치지 않습니다.",
    i000056: "모든 데이터의 입력이 끝나면 sandbox학습을 통해 적용하세요.",
    i000057: "Sandbox에서 충분한 테스트가 끝나면 서비스에 반영하세요.",
    i000058: "인텐트 구조, 학습 데이터를 그대로 유지합니다.",
    i000059: "인텐트 구조만 유지하고 학습 데이터는 초기화 합니다.",
    i000060: "자동완성",
    i000061: "다양한 데이터로 구성하는 자동완성",
    i000062: "목록으로",
    i000063: "등록된 자동완성 문장을 엑셀 파일로 다운로드 받습니다.",
    i000064: "제목을 입력하세요.",
    i000065: "토큰을 입력하세요.",
    i000066: "테스트 성공",
    i000067: "엔티티 수정",
    i000068: "수정하기",
    i000069: "사용법",
    i000070: "엔티티 이름",
    i000071: "옵션",
    i000072: "엔티티 이름을 생성해 보세요.",
    i000073: "등록",
    i000074: "대표 키워드",
    i000075: "셀렉터",
    i000076: "일치하는 문장을 처리합니다. 조사는 제외시킵니다.",
    i000077: "등록된 셀렉터를 엑셀파일로 다운로드 받습니다.",
    i000078: "선택된 엔티티가 없습니다.",
    i000079: "검색",
    i000080: "엔티티 값",
    i000081: "유의어",
    i000082: "맵핑 값",
    i000083: "등록된 엔티티가 없습니다.",
    i000084: "엔티티",
    i000085: "엔티티 이름 인식",
    i000086: "엔티티 옵션과 이름을 확인 할 수 있습니다.",
    i000087: "엔티티 값을 입력하세요.",
    i000088: "맵핑 값을 입력하세요.",
    i000089: "유의어를 입력하세요.",
    i000090: "엔티티 이름 등록",
    i000091: "제목 및 토큰 수정",
    i000092: "삭제 할 문장을 선택하세요.",
    i000093: "문장",
    i000094: "등록된 문장이 없습니다.",
    i000095: "통계",
    i000096: "일, 주, 월 단위 통계를 제공합니다.",
    i000097: "도메인",
    i000098: "데이터가 없습니다.",
    i000099: "스킬",
    i000100: "사용자 질의",
    i000101: "파일을 첨부해주세요.",
    i000102: "대량의 인텐트 등록을 엑셀을 통해 진행하세요. 아래 규칙을 따라야 합니다.",
    i000103: "최상단 행은 query, intent_name, skill_name, domain_name으로 구성되어야 합니다.",
    i000104: "active(intent 활성화 여부, TRUE, FALSE), user_key(사용자 관리 key), intent_threshold(intent별 threshold)는 선택사항 입니다.",
    i000105: "intent_answer(인텐트별 답변), phrase_answer(학습데이터별 답변)은 선택사항이며 줄바꿈으로 여러개 입력 가능합니다.",
    i000106: "현재는 엑셀(xlsx)만 지원하고 있습니다.",
    i000107: "기존의 데이터를 삭제합니다.",
    i000108: "설정",
    i000109: "에이전트 및 엔티저 설정을 변경 할 수 있습니다.",
    i000110: "에이전트 설정",
    i000111: "엔티저 설정",
    i000112: "인텐터와 디파인더 둘 중에 하나는 사용해야 합니다.",
    i000113: "에이전트 이름",
    i000114: "에이전트 이름을 입력하세요.",
    i000115: "에이전트는 여러 도메인을 그룹화 시키거나 비슷한 도메인을 구분하기 위해 사용됩니다.",
    i000116: "언어",
    i000117: "에이전트 언어 설정",
    i000118: "포스트 의도분류 태거",
    i000119: "의도분류 이후에 포스트 널 사전으로 태그를 붙힙니다. 이 옵션은 에이전트의 성능에 영향을 주지 않습니다.",
    i000120: "에이전트 토큰",
    i000121: "에이전트를 외부에서 활용할 때 필요한 토큰입니다. 클라이언트가 이 정보를 반드시 알아야 합니다.",
    i000122: "인텐터 설정",
    i000123: "인텐터는 학습 데이터가 표준화된 데이터일 경우에 적합합니다. 학습 데이터가 표준화 되어있지 않다면 이 옵션을 꺼주세요.",
    i000124: "인텐터 임계치",
    i000125: "인텐터 결과의 임계값을 설정 합니다. 에이전트는 임계값을 넘는 데이터만 사용합니다.",
    i000126: "인텐터 결과 개수",
    i000127: "인텐터 결과 개수를 설정합니다.",
    i000128: "디파인더 설정",
    i000129: "디파인더는 인텐터와 함께 에이전트의 의도분류를 도와줍니다.",
    i000130: "디파인더 임계치",
    i000131: "디파인더 결과의 임계값을 설정 합니다. 에이전트는 임계값을 넘는 데이터만 사용합니다.",
    i000132: "디파인더 결과 개수",
    i000133: "디파인더 결과 개수를 설정합니다.",
    i000134: "에이전트 활성화/비활성화",
    i000135: "에이전트의 활성화 상태를 변경합니다.",
    i000136: "엔티저 생성",
    i000137: "도메인별로 저장공간을 생성 합니다.",
    i000138: "도메인별로 Recognizer를 생성하거나 하나의 Recognizer에 모든 엔티티를 다룹니다.",
    i000139: "도메인별로 저장공간을 생성하면 데이터를 파일로부터 읽어 옵니다. NER사전이 대용량일 경우 사용합니다. 예) 음악",
    i000140: "엔티티명의 우선순위를 지정합니다. 쉼표(,)를 구분자로 입력하세요.",
    i000141: "Skip word를 설정합니다. 쉼표(,)를 구분자로 입력하세요.",
    i000142: "슬롯 등록이 쉬워집니다. Export한 파일을 이용하세요.",
    i000143: "사용중인 자동완성",
    i000144: "등록된 자동완성 데이터가 없습니다.",
    i000145: "수정",
    i000146: "자동완성 제목을 입력하세요.",
    i000147: "자동완성 업로드",
    i000148: '첫번째 행에 "text"라고 입력하세요.',
    i000149: "두번째 행부터 자동완성으로 사용될 단어 또는 문장을 입력하세요.",
    i000150: "성공율",
    i000151: "변동",
    i000152: "버전",
    i000153: "등록일",
    i000154: "등록된 테스트 케이스가 없습니다.",
    i000155: "테스트 결과",
    i000156: "테스트 데이터",
    i000157: "저장을 완로 했습니다.",
    i000158: "인텐트별 답변을 작성합니다.",
    i000159: "여러 개의 답변을 작성할 수 있습니다.",
    i000160: "Conversation maker에서 랜덤하게 노출 됩니다.",
    i000161: "삭제",
    i000162: "고객 발화를 NER 태깅 결과로 치환한 질의입니다.",
    i000163: "고객 발화로 부터 NER 사전에 등록된 엔티티 값을 추출한 결과 입니다.",
    i000164: "고객 발화로 부터 후처리 NER 사전에 등록된 엔티티 값을 추출한 결과 입니다. 의도분석 결과에는 영향이 없습니다.",
    i000165: "고객 발화를 유의어 결과로 치환한 질의입니다.",
    i000166: "고객 발화로 부터 유의어 사전에 등록된 값을 추출한 결과 입니다.",
    i000167: "에이전트 생성",
    i000168: "에이전트 목록",
    i000169: "전환",
    i000170: "도메인 이름을 입력하세요.",
    i000171: "에러가 발생했습니다.",
    i000172: "당신의 이야기를 들려주세요.",
    i000173: "대화",
    i000174: "해당 인텐트에 설정된 슬롯 정보를 바탕으로 슬롯을 채운 결과 입니다.",
    i000175: "죄송합니다. 현재는 파악할 수 없는 의도입니다.",
    i000176: "최상위만 정답으로",
    i000177: "차상위 85점 이상 정답으로",
    i000178: "도메인 등록",
    i000179: "삭제 할 유의어를 선택해주세요.",
    i000180: "등록된 유의어가 없습니다.",
    i000181: "문장을 입력하세요.",
    i000182: "셀렉터로 처리할 문장을 입력하세요.",
    i000183: "인텐트를 입력하세요.",
    i000184: "입력된 문장들을 처리할 인텐트를 입력하세요.",
    i000185: "에이전트를 만들고 새로운 도메인 정보를 학습시켜 주세요.",
    i000186: "언어를 선택하세요.",
    i000187: "에이전트 생성 성공",
    i000188: "에이전트를 생성했습니다.",
    i000189: "도메인, 스킬, 인텐트, NER 사전을 구성하세요.",
    i000190: "도메인은 음악, 날씨, 시간 등의 고객 질문의 상위 개념을 의미합니다.",
    i000191: "인텐트는 고객 질문의 의도를 의미합니다.",
    i000192: "'오늘 날씨 어때?'는 'searchWeather'로 정의할 수 있습니다.",
    i000193: "스킬은 비슷한 종류의 Intent를 묶는 단위입니다.",
    i000194: "'볼륨 올려줘', '볼륨 내려줘'등은 'volume' Skill로 정의할 수 있습니다.",
    i000195: "NER은 개체명을 의미합니다.",
    i000196: "'아이유 좋은날 틀어줘'에서 개체명은 '가수명:아이유', '노래명:좋은날'등을 정의할 수 있습니다.",
    i000197: "도메인 생성부터 시작해 볼까요?",
    i000198: "오타교정",
    i000199: "사전 기반으로 오타를 교정합니다",
    i000200: "등록된 오타교정 사전 데이터를 엑셀파일로 다운로드 합니다.",
    i000201: "오타교정 사전 데이터 파일",
    i000202: "보정 키워드",
    i000203: "보정할 키워드를 입력하세요.",
    i000204: "오타로 입력된 키워드를 보정할 키워드를 입력합니다.",
    i000205: "오타 키워드",
    i000206: "오타를 입력하세요.",
    i000207: "보정할 오타를 입력합니다. 여러 개의 오타를 입력할 수 있습니다.",
    i000208: "대량의 오타 보정 키워드 등록을 엑셀 파일을 통해 진행하세요.",
    i000209: "첫번째 행은 correction_keyword, typo으로 구성되어야 합니다.",
    i000210: "파일 용량에 제한은 없지만 가급적이면 1만건 단위로 데이터를 입력해 주세요.",
    i000211: "테스트를 등록해서 성능을 확인하세요.",
    i000212: "질문",
    i000213: "기대값",
    i000214: "결과",
    i000215: "점수",
    i000216: "삭제 할 오타교정 데이터를 선택해주세요.",
    i000217: "등록된 오타교정 데이터가 없습니다.",
    i000218: "현재 버전의 데이터를 Sandbox에 반영하시겠습니까?",
    i000219: "Sandbox 학습을 시작 했습니다.",
    i000220: "현재 Sandbox 버젼의 데이터를 서비스에 반영하시겠습니까?",
    i000221: "서비스에 반영중 입니다.",
    i000222: "선택한 도메인이 없습니다.",
    i000223: "도메인은 학습 분야를 분류하는 단위입니다.",
    i000224: "체크아웃",
    i000225: "Sandbox 학습",
    i000226: "서비스 반영",
    i000227: "버전",
    i000228: "요청 수",
    i000229: "의도분석 시간별 분포",
    i000230: "인텐트 답변을 학습 문장별로 구성합니다.",
    i000231: "인텐트 명을 입력하세요.",
    i000232: "인텐트 임계점 설정",
    i000233: "인텐트별 임계점 사용여부",
    i000234: "점 이상",
    i000235: "스킬 수정",
    i000236: "스킬명을 입력하세요.",
    i000237: "질의를 입력하세요.",
    i000238: "슬롯 채우기 과정을 통해 고객에게 분석결과를 전달하세요.",
    i000239: "인텐트 이름을 클릭하시면 인텐트로 학습된 데이터를 확인할 수 있습니다.",
    i000240: "데이터별 특성을 확인해 슬롯 설정을 해주세요.",
    i000241: "학습 데이터",
    i000242: "파라미터를 입력하세요.",
    i000243: "엔티티를 입력하세요.",
    i000244: "기본값을 입력하세요.",
    i000245: "선택한 엔티티 이름이 없습니다.",
    i000246: "등록된 엔티티 이름이 없습니다.",
    i000247: "엔티티 추가",
    i000248: "등록된 객체명 사전을 다운받습니다.",
    i000249: "에이전트는 도메인에 따라서 엔티티를 관리합니다.",
    i000250: "원하는 인텐트로 보내고 싶은 한 단어 혹은 문장을 대량으로 등록합니다.",
    i000251: "전체 목록보기",
    i000252: "정말 삭제 하시겠습니까?",
    i000253: "확인",
    i000254: "도메인/인텐트/학습결과 확인",
    i000255: "로그아웃",
    i000256: "클러스터링",
    i000257: "유사한 사용자 질의 모음",
    i000258: "인텐트 목록",
    i000259: "스킬 목록",
    i000260: "최근에 사용한 에이전트",
    // i000261: "셀렉터 데이터를 전부 삭제하시겠나요?",
    // i000262: "셀렉터 삭제",
    // i000263: "모든 셀렉터를 삭제 합니다. 삭제한 셀렉터는 되돌릴 수 없습니다.",
    i000264: "변경사항이 있습니다. 변경사항을 취소하고 다른 페이지로 이동할까요?",
    i000265: "학습문장별 답변",
    i000266: "인텐트 답변",
    i000267: "도메인 수정",
    i000268: "개체명 처리 중입니다.",
    i000269: "개체명 처리를 완료 했습니다.",
    i000270: "파일",
    i000271: "클러스터링 데이터를 엑셀을 통해 대량으로 등록합니다.",
    i000272: "작업중인 데이터가 있습니다.",
    i000273: "작업 등록에 성공 했습니다.",
    i000274: "클러스터링 작업 중 입니다.",
    i000275: "엑셀 파일을 통해서 클러스터링 작업 중 입니다.",
    i000276: "클러스터링 작업 완료",
    i000277: "엑셀 파일을 통해서 클러스터링 작업을 완료 했습니다.",
    i000278: "데이터가 없습니다.",
    i000279: "클러스터링 목록",
    i000280: "도메인 생성이 필요합니다.",
    i000281: "에이전트를 활성화했습니다.",
    i000282: "에이전트를 비활성화했습니다.",
    // i000283: "비활성화 agent 보이기",
    i000284: "제목 및 토큰은 다른 자동완성 목록과 겹칠 수 없습니다.",
    i000285: "추가할 키워드를 입력하세요.",
    i000286: "이미 존재하는 제목입니다.",
    // i000287: "다량의 데이터를 업로드시 모든 데이터가 반영될 때까지 다소 시간이 소요니다.",
    i000288: "시나리오에 사용 중인 자동완성의 토큰을 바꿀 경우 재배포를 해야합니다.",
    // i000289: "현재 키워드들은 모두 지워지고 업로드된 파일의 키워드들로 대체됩니다.",
    i000290: "수정된 내용이 없습니다.",
    i000291: "업로드 작업을 시작합니다.",
    i000292: "수정되지 않은 키워드입니다.",
    i000293: "인텐트를 먼저 생성해주세요",
    i000294: "시스템 엔티티",
    i000295: "자동완성 테스트는 각 자동완성 페이지에서만 기능합니다.",
    i000296: "인텐트 수정",
    i000297: "키워드 수정",
    i000298: "키워드",
    i000299: "키워드를 입력하세요.",
    i000300: "위의 키워드는 이미 보정 키워드나 오타 키워드로 쓰이고 있어 업로드 되지 않았습니다.",
    i000301: "존재하지 않거나 비활성화된 에이전트입니다.",
    i000302: "위의 키워드는 이미 대표 키워드나 유의어로 쓰이고 있어 업로드 되지 않았습니다.",
    i000303: "위의 유의어는 이미 엔티티 이름이나 값, 유의어로 쓰이고 있어 업로드 되지 않았습니다.",
    i000304: "같은 domain 내 동일한 intent 이름은 생성할수 없습니다.",
    i000305: "테스트 작업을 시작합니다.",
    i000306: "엑셀파일(xls,xlsx)만 첨부하실 수 있습니다.",
    i000307: "파일은 용량은 20MB 이하여야합니다.",
    i000308: "샘플 엑셀 파일 받기",
    i000309: "Agent 전체 불러오기",
    i000310: "도메인/오타교정/엔티티/유의어/셀렉터를 엑셀파일로 불러옵니다.(자동완성은 제외)",
    i000311: "버전 배포 내역을 확인합니다.",
    i000312: "도메인/오타교정/엔티티/유의어/셀렉터를 엑셀파일로 내려받습니다.(자동완성은 제외)",
    i000313: "배포 방식",
    i000314: "구분",
    i000315: "배포 시간",
    i000316: "관리도구",
    i000317: "이 버전으로 서비스 배포",
    i000318: "배포 시점 에이전트 엑셀 받기",
    i000319: "인텐트 업로드 작업 이후 다시 샌드박스 학습 및 서비스 반영하는 것을 권장드립니다.",
    i000320: "상태",
    i000321: "선택된 인텐트가 없습니다.",
    i000322: "적용",
    i000323: "전체 해제",
    i000324: "전체 선택",
    i000325: "타입 정렬",
    i000326: "이름 정렬",
    // i000327: "이름 정렬로",
    // i000328: "옵션 정렬로",
    i000329: "첫번째 행은 keyword, synonym으로 구성되어야 합니다.",
    i000330: "첫번째 행은 text, intent_name으로 구성되어야 합니다.",
    i000331: "유의어를 추가하세요.",
    i000332: "유의어들 사이에서 가장 대표적으로 또는 일반적으로 사용되는 키워드를 입력합니다.",
    i000333: "유의어를 입력합니다. 여러 개의 유의어를 입력할 수 있습니다.",
    i000334: "(삭제됨)",
    i000335: "도메인이 없습니다.",
    i000336: "{job} 작업이 완료되었습니다.",
    i000337: "{job} 작업 중 에러가 발생했습니다.",
    i000338: "엔티티 목록",
    i000339: "자동완성 목록",
    i000340: "에이전트 업로드",
    i000341: "에이전트 다운로드",

    i000342: "멤버 관리",
    i000343: "초대 수락",
    i000344: "거절",
    i000345: "에이전트 초대",
    i000346: "초대",
    i000347: "멤버 삭제",
    i000348: "멤버 초대",
    i000349: "멤버 수정",
    i000350: "기본",
    i000351: "소유자 변경",
    i000352: "해당 에이전트의 소유자를 {username} 님으로 변경하시겠습니까?",
};

export default translation;
